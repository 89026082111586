import React, {useState} from "react";
import Dialog from "../../components/dialogs/Dialog";
import DialogTitle from "../../components/dialogs/DialogTitle";
import {_transl} from "../../store/localization/TranslMessasge";
import DialogContent from "../../components/dialogs/DialogContent";
import Grid from "../../components/dialogs/Grid";
import TextField from "../../components/fields/textfield/TextField";
import DialogActions from "../../components/dialogs/DialogActions";
import {SaveButton} from "../../components/button/SaveButton";
import {CancelButton} from "../../components/button/CancelButton";
import Snackbar from "../../pages/main/content/snackbar/Snackbar";
import {CommonTranslation} from "../../pages/main/content/CommonTranslation";
import {IModelDto} from "../apis/model/IModelDto";
import importsService from "../apis/ImportsService";
import {UserDto} from "../apis/user/UserDto";
import {DiagramsGridAction, DiagramsGridActionType} from "../../pages/main/content/diagrams/DiagramsGridAction";
import RenderMode from "./context/RenderMode";
import RouteDefinitionUtils from "../routedefinition/RouteDefinitionUtils";
import {diagramEditorPage} from "../routes/AppRoutes";
import {useNavigate} from "react-router-dom";
import Constants from "../Constants";
import TranslationKey from "./TranslationKey";

export interface CopyOfModelDialogProps {
    model: IModelDto;
    user: UserDto;
    onSuccess: () => void;
    onClosed: () => void;
}

export default function CopyOfModelDialog(props: CopyOfModelDialogProps) {
    const {model, user, onSuccess, onClosed} = props;

    const [name, setName] = useState<string>("");
    const [nameValidationMsg, setNameValidationMsg] = useState<string | undefined>(undefined);

    const navigate = useNavigate();

    function isNameValid(): boolean {
        const isValid = name.trim().length !== 0;
        setNameValidationMsg(isValid ? undefined : _transl(CommonTranslation.FILL_OUT_THIS_FIELD));
        return isValid;
    }

    const onDialogSave = () => {
        if (isNameValid()) {
            model.diagrams[0].diagramInfo.name = name;
            importsService.createCopyOfModelJSON(model, user,
                (id: string) => {
                    showDiagramEditorDialog(id);
                    onSuccess();
                },
                () => {
                    Snackbar.error(_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_SAVE_FAILED));
                    onClosed();
                });
        }
    }

    function showDiagramEditorDialog(id: string) {
        const queryParams = [{name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR), value: id}];
        queryParams.push({
            name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.EDITOR_MODE),
            value: RenderMode.EDIT
        })
        const path = Constants.FE_APP_MAIN_ROUTE + "/" + RouteDefinitionUtils.resolvePath(diagramEditorPage, queryParams);
        navigate(path);
    }

    return (
        <React.Fragment>
            <Dialog open={true}
                    onClose={onClosed}
                    fullWidth={true}>
                <DialogTitle id="scroll-dialog-title"
                             title={_transl(TranslationKey.DIAGRAMS_DIAGRAMEDITOR_SAVE_AS_NEW_TITLE)}
                             onDialogClosed={onClosed}/>
                <DialogContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <TextField id="value-field"
                                       label={_transl(CommonTranslation.TITLE)}
                                       value={name}
                                       required={true}
                                       errorMessage={nameValidationMsg}
                                       onChange={(name) => setName(name)}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <SaveButton onClick={onDialogSave}/>
                    <CancelButton onClick={onClosed} variant={"outlined"}/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}