import chatService, {ChatDto, ChatState} from "../../../../../common/apis/chat/ChatService";
import {Info} from "./ChatAccordion";
import {IconButton, Tooltip} from "@mui/material";
import ChatAvatar from "../../../../../components/chat/ChatAvatar";
import ChatResolvingButton from "../ChatResolvingButton";
import {ActionButton} from "../../../../../components/button/ActionButton";
import Delete from "@mui/icons-material/Delete";
import {_transl} from "../../../../../store/localization/TranslMessasge";
import {DiagramsGridAction, DiagramsGridActionType} from "../../diagrams/DiagramsGridAction";
import RouteDefinitionUtils from "../../../../../common/routedefinition/RouteDefinitionUtils";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {useContext, useState} from "react";
import LocaleContext, {Locale} from "../../../../../common/LocaleContext";
import {TimeAgoFormatter} from "../../../../../common/TimeAgoFormatter";
import UserFormatter from "../../users/UserFormatter";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";
import ConfirmationDialog from "../../../../../components/dialogs/ConfirmationDialog";
import {ChatTranslationKey} from "../ChatTranslationKey";
import * as React from "react";
import Snackbar from "../../snackbar/Snackbar";
import {ErrorTranslationKey} from "../../ErrorTranslationKey";
import DescriptionIcon from "@mui/icons-material/Description";
import {diagramEditorPage} from "../../../../../common/routes/AppRoutes";

interface ChatAccordionHeaderProps {
    chat: ChatDto,
    info: Info | undefined,
    onChatUpdated: () => void,
    onChatDeleted: () => void,
    openElementDetail?: (id: string) => void;
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        author: {
            fontWeight: 'bold',
            display: 'flex',
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        timeAgo: {
            color: 'gray',
            paddingLeft: theme.spacing(1),
            paddingTop: theme.spacing(1),
            whiteSpace: "nowrap"
        },
        divFlex: {
            display: 'flex'
        },
        buttonsDiv: {
            paddingLeft: theme.spacing(1),
            whiteSpace: "nowrap"
        },
        rightButton: {
            marginLeft: 'auto'
        }
    })
);

export default function ChatAccordionHeader(props: ChatAccordionHeaderProps) {
    const {chat, info, onChatUpdated, onChatDeleted, openElementDetail} = props;

    const classes = useStyles();

    const [deleteConfirmationDialogOpened, setDeleteConfirmationDialogOpened] = useState(false);

    const {language, locale} = useContext<Locale>(LocaleContext);
    const timeAgoFormatter = new TimeAgoFormatter(language);

    const comment = chat.chatComments[0];
    const authorName = UserFormatter.formatFullName(comment.user.firstName, comment.user.lastName);
    const timeAgo = timeAgoFormatter.formatAsTimeAgo(new Date(comment.created));

    function showDiagramEditorDialogInViewMode(id: string) {
        const queryParams = [{name: DiagramsGridAction.getQueryDataKey(DiagramsGridActionType.SHOW_EDITOR), value: id}];
        window.open(RouteDefinitionUtils.resolvePath(diagramEditorPage, queryParams), '_blank');
    }

    function deleteChat(event: Event) {
        event.stopPropagation();
        chatService.deleteChat(chat.id)
            .then(() => {
                setDeleteConfirmationDialogOpened(false);
                onChatDeleted();
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_REMOVE_DATA)));
    }

    function onStateChange(chatState: ChatState, event: Event) {
        event.stopPropagation();
        chatService.updateChatState(chat.id, chatState)
            .then(() => {
                onChatUpdated();
            })
            .catch(() => Snackbar.error(_transl(ErrorTranslationKey.FAILED_TO_SAVE_DATA)));
    }

    const buildInfoTooltipTitle = (inputName: string, inputTitle: string | undefined) => {
        return (
            <div>
                {inputName}
                <br />
                {inputTitle}
            </div>
        );
    };

    return (
        <div className={classes.divFlex}>
            <div className={classes.divFlex}>
                <ChatAvatar name={authorName}/>
                <span className={classes.author}>
                    {authorName}
                </span>
                <Tooltip title={new Date(comment.created).toLocaleString([locale])} placement={"top"}>
                    <span className={classes.timeAgo}>
                        {timeAgo}
                    </span>
                </Tooltip>
            </div>
            <div className={classes.buttonsDiv}>
                <ChatResolvingButton chatState={chat.state}
                                     onChatStateChange={(chatState, event) => onStateChange(chatState, event)}
                                     disabled={!chat.acl.canChangeState}/>
                <ActionButton id={"delete-comment-button"}
                              onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmationDialogOpened(e);
                              }}
                              icon={<Delete/>}
                              tooltip={_transl(ChatTranslationKey.REMOVE_CHAT)}
                              color={"default"}
                              disabled={!chat.acl.canDelete}/>
            </div>
            {info && info.viewDiagram &&
                <div className={classes.rightButton}>
                    <Tooltip title={buildInfoTooltipTitle(_transl(ChatTranslationKey.SHOW_DIAGRAM), info.title)}
                             placement={"top"}>
                        <IconButton
                            color={"default"}
                            component="span"
                            onClick={(event: { stopPropagation: () => void; }) => {
                                event.stopPropagation();
                                showDiagramEditorDialogInViewMode(info.id);
                            }}
                            size={"medium"}>
                            <AccountTreeIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            }
            {info && info.viewElement &&
                <div className={classes.rightButton}>
                    <Tooltip title={buildInfoTooltipTitle(_transl(ChatTranslationKey.SHOW_ELEMENT_DETAIL), info.title)}
                             placement={"top"}>
                        <IconButton
                            color={"default"}
                            component="span"
                            onClick={(event: { stopPropagation: () => void; }) => {
                                event.stopPropagation();
                                openElementDetail!(info.id);
                            }}
                            size={"medium"}>
                            <DescriptionIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
            }
            <ConfirmationDialog open={deleteConfirmationDialogOpened}
                                title={_transl(ChatTranslationKey.DELETE_CONFIRMATION_TITLE)}
                                confirmationText={_transl(ChatTranslationKey.DELETE_CONFIRMATION_TEXT)}
                                onConfirm={(e) => deleteChat(e)}
                                onReject={(e) => {
                                    e.stopPropagation();
                                    setDeleteConfirmationDialogOpened(false)
                                }}/>
        </div>);
}